<template>
  <div class="orderDetail">
    <div class="main">
      <van-cell-group inset style="margin-bottom: 16px">
        <van-cell title="订单号">
          <div slot="default">
            {{orderData.order_no}}
          </div>
        </van-cell>
        <van-cell title="下单时间" :value="orderData.created_at" />
        <van-cell title="类型" :value="orderData.type_text" />
        <van-cell title="状态" :value="orderData.status_text" />
      </van-cell-group>
      <van-cell-group inset>
        <van-cell :title="orderData.type == 1 ? '联系人' : '提货人'" :value="orderData.real_name" />
        <van-cell title="电话" :value="orderData.user_phone" />
      </van-cell-group>
      <van-cell-group inset title="商品" style="margin-bottom: 16px" v-if="orderData.product">
        <van-card
          num="1"
          :title="orderData.product.store_name"
          :thumb="orderData.product.image  + '?x-oss-process=image/resize,w_250/quality,q_60'"
        />
      </van-cell-group>
      <van-cell-group inset>
        <van-cell title="">
          <div slot="default">
            合计 ￥  <span :style="{color: $themeColor, fontSize: '18px'}">{{orderData.pay_price}}</span>
          </div>
        </van-cell>
      </van-cell-group>
    </div>

    <div class="footerBtn">
      <van-button v-if="orderData.status == 6" :color="$themeColor" round block @click="pay(orderDataorderDataorderData.order_no)">支付订单</van-button>
      <van-button v-if="orderData.status == 4" :color="$themeColor" round block @click="showCheck">自提核销</van-button>
    </div>

    <van-dialog v-model="showCheckModal" theme="round-button" :confirm-button-color="$themeColor">
      <div class="code">
        <div id="qrCode" ref="qrCode"></div>
        <div class="tips">请向工作人员出示二维码</div>
      </div> 
    </van-dialog>
  </div>
</template>
<script>
import { orderShow, placeOrder } from '@/api/shop'
import { getSdkConfig } from '@/api/common'
import Vue from 'vue';
import { Image as VanImage, Tag, Card, Button, Cell, CellGroup, Dialog } from 'vant';
Vue.use(VanImage);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(  Dialog);
import QRCode from 'qrcodejs2'
export default  {
  data() {
    return {
      orderId: '',
      orderData: {},
      showCheckModal: false
    }  
  },
  created () {
    if (this.$route.query.id) {
      this.orderId = this.$route.query.id
    }
    // 获取订单详情
    this.getShow()
  },
  methods: {
    getShow () {
      orderShow({
        id: this.orderId
      }).then(res => {
        this.orderData = res.data
      })
    },
    showCheck () {
      this.showCheckModal = true
        setTimeout(() => {
            this.$refs.qrCode.innerHTML = ''
            new QRCode(this.$refs.qrCode, {
                text: JSON.stringify({
                  type: 'product',
                  value: this.orderData.encrypt_verify_code
                }),
                width: 200,
                height: 200,
            })
        },400);
    },
    pay (code) {
      var origin = window.location.origin;
      var a = origin.substr(5).search(":");
      var uri = origin + window.location.pathname;
      Toast.loading({
        message: '支付中'
      })
      getSdkConfig({
        url: uri,
        apiList: ['chooseWXPay']
      }).then(res => {
        // 初始化
        this.$wx.init(res.data, () => {
           
          placeOrder({
            order_sn: code
          }).then(order => {
            this.$wx.pay(
              order.data, 
              payRes => {
                Toast({
                  type: 'success',
                  message: '支付成功',
                  onClose: () => {
                    this.getShow()
                  }
                })
              },
              payRes => {
                // 取消回调
                Toast({
                  type: 'fail',
                  message: '支付已取消',
                })
              },
              payRes => {
                // 取消回调
                Toast({
                  type: 'fail',
                  message: '支付失败',
                })
              }
            )
          })
        })
      })
    },
    
  }
}
</script>

<style scoped lang="less">
.orderDetail {
  min-height: 100vh;  
  background-color: #f5f5f5;

  .main {
    padding: 12px;

    /deep/.van-cell-group--inset {
      margin: 0;
    }

    /deep/.van-cell-group__title--inset {
      padding: 16px 16px 8px 16px;
    }

    /deep/.van-card {
      background: #fff;
      padding: 10px;
    }
  }

  .footerBtn {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 12px;
    
    padding-bottom: calc(12px + constant(safe-area-inset-bottom));//兼容 IOS<11.2
    padding-bottom: calc(12px + env(safe-area-inset-bottom));//兼容 IOS>11.2
  }

  .code {
    padding: 30px 10px 20px;
    text-align: center;
    overflow-y: auto;
    max-height: 68vh;

    #qrCode {
      display: inline-block;
      height: 200px;
      margin-bottom: 16px;
    }

    .tips {
      font-size: 14px;
    }
  }
}
</style>